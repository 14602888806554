<template>
    <div>
        <form @submit.prevent="submitForm">
            <input type="file" ref="fileInput" @change="handleFileInputChange">
            <button type="submit">
                Submit
            </button>
        </form>
    </div>
</template>

<script>

export default {
    name: 'Create',
    inject: ['http', 'route'],
    data() {
        return {
            file: null,
        };
    },
    methods: {
        handleFileInputChange(event) {
            // eslint-disable-next-line prefer-destructuring
            this.file = event.target.files[0];
        },
        fetch() {
            const formData = new FormData();
            formData.append('excel_file', this.file);

            fetch(this.route('score.store'), {
                method: 'POST',
                body: formData,
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(response.statusText);
                    }
                    return response.json();
                })
                // eslint-disable-next-line no-unused-vars
                .then(data => {
                    // console.log(data);
                })
                .catch(this.errorHandler);
        },

    },
};
</script>

<style lang="scss">
</style>
